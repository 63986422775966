@media screen and (min-width: 992px){
	.hr-left {
		width: 85%;
	    background: black;
		margin: 23px 0;
		clear: none;
		float: left;
		display: block;
		&.short {
			width: 80%;
		}
	}
	.hr-right {
		width: 85%;
		background: black;
		margin: 23px 0;
		clear: none;
		float: right;
		display: block;
		&.short {
			width: 80%;
		}
	}
	.hr-footer {
		width: 100%;
		background: black;
		margin: 23px 0;
	}

	.about-title {
		text-align: right;
		display: block;
	}

	.headshot {
		width: 100%;
	}

	.nav {
		display: block;
	}

	#menu-click {
		display: none;
	}

	.main-menu {
		margin-top: 4rem;
		display: flex;
		justify-content: right;

		.menu-item {
			font-family: Amaranth;
			margin-left: .625rem;
			margin-right: .625rem;
			display: flex; 
			cursor: pointer;
			align-items: center;
			color: #000;
			text-transform: uppercase;
			font-size: 18px;
			font-weight: 500;

			&.active {
				color: #e52949;
			}

			&.resume {
				border-radius: 5px;
				border: 2px solid #e52949;
				padding: 5px;

				&:hover {
					background: #e52949;
					color: #FFF;
				}
			}
		}

		.menu-item:last-of-type {
			margin-right: 0px;
		}
	}
}