@media screen and (min-width: 576px) {
	.hr-left {
		width: 70%;
		&.short {
			width: 60%;
		}
	}
	.hr-right {
		width: 70%;
		&.short {
			width: 60%;
		}
	}
}