@media screen and (min-width: 768px){
	.padme {
		padding-bottom: 0px;
	}

	.hr-left {
		width: 80%;
		&.short {
			width: 70%;
		}
	}
	.hr-right {
		width: 80%;
		&.short {
			width: 70%;
		}
	}
}