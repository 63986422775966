@import "variables";

* {
	box-sizing: border-box;
	font-family: 'Jost', sans-serif;
}

body {
	font-size: 16px;
	background: #FFF;
	color: $black;
}

a {
	color: $blue;
	&:hover {
		text-decoration: underline;
		color: $blue;
	}
}

.no-gutter > [class*='col-'] {
	padding-right:2px;
	padding-left:2px;
}

/* centered columns styles */
.row-centered {
	text-align:center;

	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
}
.col-centered {
	display:inline-block;
	float:none;
	/* reset the text-align */
	text-align:left;
	/* inline-block space fix */
	margin-right:-4px;
	vertical-align: top;
}

div.background {
	background-image: url('/img/background.webp');
	min-height: 100vh;
	width: 100%;
	background-size: cover;
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
}

.header {
	margin-top: 2rem;
	margin-bottom: 1rem;
}

h1 {
	font-family: Amaranth;
	font-size: 36px;
	text-transform: uppercase;
	font-weight: 500;
}

h2 {
	font-family: Amaranth;
	font-size: 36px;
	color: #231f20;
	text-transform: uppercase;
	font-weight: 500;
}

h3 {
	font-family: Amaranth;
	font-size: 26px;
	color: #231f20;
	text-transform: uppercase;
	font-weight: 500;
	margin-bottom: 20px;
	
	&.details {
		padding-top: 20px;
	}
}

h4 {
	font-family: Amaranth;
	font-size: 1.5rem;
}

h5 {
	font-style: italic;	
	font-size: 1.25rem;
}

h6 {
	font-size: 1.25rem;
}

p {
	font-size: 22px;
	line-height: 34px;
	letter-spacing: 1px;
	text-align: justify;
}

ul {
	padding-left: 15px;
	li {
		font-size: 18px;
		line-height: 26px;
		letter-spacing: 1px;
		text-align: justify;
	}
}
.tech {
	font-size: 16px;
	line-height: 26px;
	letter-spacing: 1px;
	text-align: justify;
}

.logo {
	padding-bottom: 10px;
}
.icon-text {
	display: flex;
}
.icon {
	background: #FFF;
	border-radius: 5px;
	width: 40px;
	height: 40px;
	text-align: center;
	padding-top: 7px;
	margin-bottom: 10px;
}
.text {
	padding-top: 5px;
	margin-left: 10px;
	font-size: 22px;
	line-height: 34px;
	letter-spacing: 1px;
}

.nav {
	display: none;
}

.hr-left {
	width: 60%;
    background: black;
	margin: 23px 0;
	clear: none;
	float: left;
	&.short {
		width: 45%;
	}
}
.hr-right {
	width: 60%;
	background: black;
	margin: 23px 0;
	clear: none;
	float: right;
	&.short {
		width: 45%;
	}
}
.hr-footer {
	width: 100%;
	background: black;
	margin: 23px 0;
}

.about-title {
	text-align: right;
	display: none;
}

.headshot-image {
	text-align: center;
}

.headshot {
	max-width: 100%;
	width: 85%;
}
.summary-col {
	margin-top: 2rem;
}

.big-details {
	display: none;
}

.szs {
	margin-bottom: 2rem;
}

.summary {
	margin-top: 1rem;
}
.skills {
	margin-top: 2rem;
	.skill {
		border-radius: 5px;
		padding: 2rem;
		margin-bottom: 2rem;
		p, h4 {
			text-align: center;
		}
	}
	.backend {
		background: rgba(240, 227, 233, .2);
	}
	.frontend {
		background: rgba(220, 240, 241, .2);
	}
	.data {
		background: rgba(215, 219, 194, .2);
	}
	.devops {
		background: rgba(0, 171, 202, .05);
	}
	.drupal {
		background: rgba(0, 192, 133, .05);
	}
	.drupal2 {
		background: rgba(255, 116, 67, .05);
	}
	.measuring {
		background: rgba(147, 59, 139, .05);
	}
	.styling {
		background: rgba(229, 41, 73, .05);
	}
	.tooling {
		background: rgba(240, 227, 233, .2);
	}
}
.services {
	margin-top: 1rem;
	.service {
		border-radius: 5px;
		padding: 2rem;
		margin-bottom: 2rem;
		background: #FFF;
		p, h4 {
			text-align: center;
		}
	}
}
.blob {
	background-image: url('/img/blob.svg');
	width: 60px;
	height: 60px;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 1rem;

	&.blue {
		background-image: url('/img/blob-blue.svg');
		padding-top: 15px;
		padding-left: 11px;
	}
	&.green {
		background-image: url('/img/blob-green.svg');
		padding-top: 12px;
		padding-left: 10px;
	}
	&.yellow {
		background-image: url('/img/blob-yellow.svg');
		padding-top: 12px;
		padding-left: 11px;
	}
	&.red {
		background-image: url('/img/blob-red.svg');
		padding-top: 12px;
		padding-left: 10px;
	}
	&.pink {
		background-image: url('/img/blob-pink.svg');
		padding-top: 12px;
		padding-left: 11px;
	}
	&.purple {
		background-image: url('/img/blob-purple.svg');
		padding-top: 12px;
		padding-left: 11px;
	}
}

.foot {
	margin-bottom: 2rem;
}

.clients {
	img {
		max-width: 65%;
		margin-bottom: 1rem;
	}
}
.jobs {
	margin-top: 1rem;
	.job {
		border-radius: 5px;
		padding: 2rem;
		margin-bottom: 2rem;
		background: #FFF;

		.title {
			display: flex;
			width: 100%;
			align-items: flex-end;
			flex-wrap: wrap;

			h4, h5 {
				flex-basis: 65%;
			}

			img, picture, h6 {
				margin-left: auto;
			}

			h6 {
				align-self: flex-start;
			}
		}
	}
}
.education {
	margin-top: 1rem;
	
	.school {
		border-radius: 5px;
		padding: 2rem;
		margin-bottom: 2rem;
		background: #FFF;

		.title {
			display: flex;
			width: 100%;
			align-items: flex-end;
			flex-wrap: wrap;

			h4, h5 {
				flex-basis: 70%;
			}

			img, h6 {
				margin-left: auto;
			}

			h6 {
				align-self: flex-start;
			}
		}
	}
}
.portfolio {
	margin-top: 2rem;

	.card {
		padding: 2rem;
		margin-bottom: 2rem;
		text-align: center;
		cursor: pointer;

		.card-body {
			padding-bottom: 0px;
		}
		.card-text {
			font-style: italic;
			font-size: 16px;
			text-align: center;
			display: none;
		}

		&.hack {
			background: rgba(240, 227, 233, .2);
		}
		&.ameren {
			background: rgba(220, 240, 241, .2);
		}
		&.ipc {
			background: rgba(215, 219, 194, .2);
		}
		&.widjets {
			background: rgba(0, 171, 202, .05);
		}
		&.imo {
			background: rgba(0, 192, 133, .05);
		}
		&.polyone {
			background: rgba(255, 116, 67, .05);
		}
		&.alteryx {
			background: rgba(147, 59, 139, .05);
		}
		&.ep {
			background: rgba(229, 41, 73, .05);
		}
		&.philips {
			background: rgba(240, 227, 233, .2);
		}
		&.tss {
			background: rgba(220, 240, 241, .2);
		}
		&.sel {
			background: rgba(215, 219, 194, .2);
		}
		&.ocharleys {
			background: rgba(0, 171, 202, .05);
		}
		&.hourhog {
			background: rgba(0, 192, 133, .05);
		}
		&.teamgoblin {
			background: rgba(0, 171, 202, .05);
		}
		&.thxdog {
			background: rgba(255, 116, 67, .05);
		}

		.img-wrapper {
		    display: inline-block;
		    overflow: hidden;
		    border: 5px solid #FFF;
		    img {
		    	transform:scale(1.5);
			    -ms-transform:scale(1.5); /* IE 9 */
			    -moz-transform:scale(1.5); /* Firefox */
			    -webkit-transform:scale(1.5); /* Safari and Chrome */
			    -o-transform:scale(1.5); /* Opera */
			    transition: transform .2s;
			    border: 5px solid #FFF;

			    &:hover {
					transform:scale(1);
				    -ms-transform:scale(1); /* IE 9 */
				    -moz-transform:scale(1); /* Firefox */
				    -webkit-transform:scale(1); /* Safari and Chrome */
				    -o-transform:scale(1); /* Opera */			    
			    }
		    }
		}
	}
}

.modal-img {
	max-width: 100%;
}
.modal-footer {
	justify-content: flex-start;
}
.carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='black' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='black' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}


/* General styles for all menus */
.cbp-spmenu {
	background: $blue;
	position: fixed;
}

.cbp-spmenu h3 {
	color: $blue;
	padding: 20px;
	margin: 0;
	font-weight: 300;
	background: $black;
}

.cbp-spmenu a {
	display: block;
	color: #fff;
}

.cbp-spmenu a:hover {
	background: $blue;
	text-decoration: none;
}

.cbp-spmenu a:active {
	background: #afdefa;
	color: #47a3da;
}

/* Orientation-dependent styles for the content of the menu */

.cbp-spmenu-vertical {
	width: 100vw;
	height: 100%;
	top: 0;
	z-index: 1000;
}

.cbp-spmenu-vertical a {
	padding: 1em;
}

.cbp-spmenu-horizontal {
	width: 100%;
	height: 150px;
	left: 0;
	z-index: 1000;
	overflow: hidden;
}

.cbp-spmenu-horizontal h3 {
	height: 100%;
	width: 20%;
	float: left;
}

.cbp-spmenu-horizontal a {
	float: left;
	width: 20%;
	padding: 0.8em;
	border-left: 1px solid #258ecd;
}

/* Vertical menu that slides from the left or right */

.cbp-spmenu-left {
	left: -100vw;
}

.cbp-spmenu-right {
	right: -100vw;
}

.cbp-spmenu-left.cbp-spmenu-open {
	left: 0px;
}

.cbp-spmenu-right.cbp-spmenu-open {
	right: 0px;
}

/* Horizontal menu that slides from the top or bottom */

.cbp-spmenu-top {
	top: -150px;
}

.cbp-spmenu-bottom {
	bottom: -150px;
}

.cbp-spmenu-top.cbp-spmenu-open {
	top: 0px;
}

.cbp-spmenu-bottom.cbp-spmenu-open {
	bottom: 0px;
}

/* Push classes applied to the body */

.cbp-spmenu-push {
	overflow-x: hidden;
	position: relative;
	left: 0;
}

.cbp-spmenu-push-toright {
	left: 240px;
}

.cbp-spmenu-push-toleft {
	left: -240px;
}

/* Transitions */

.cbp-spmenu,
.cbp-spmenu-push {
	-webkit-transition: all 0.3s ease;
	-moz-transition: all 0.3s ease;
	transition: all 0.3s ease;
}

/* Example media queries */

@media screen and (max-width: 55.1875em){

	.cbp-spmenu-horizontal {
		font-size: 75%;
		height: 110px;
	}

	.cbp-spmenu-top {
		top: -110px;
	}

	.cbp-spmenu-bottom {
		bottom: -110px;
	}

}

@media screen and (max-height: 26.375em){

	.cbp-spmenu-vertical {
		font-size: 90%;
		width: 190px;
	}

	.cbp-spmenu-left,
	.cbp-spmenu-push-toleft {
		left: -190px;
	}

	.cbp-spmenu-right {
		right: -190px;
	}

	.cbp-spmenu-push-toright {
		left: 190px;
	}
}

#menu-click {
	cursor: pointer;
}

.x-btn {
	cursor: pointer;
	font-size: 40px;
	font-weight: 700;
	line-height: 0;
	color: #FFF;
	background: none;
	border: none;
	padding: 0;
	float: right;
	margin-top: 0px;
}


.hamburger {
	position: absolute;
	display: block;
	right: 20px;
	top: 0px;
}

#cbp-spmenu-s1 {
	text-align: center;
	font-size: 26px;

	h3 {
		padding: 20px;
	}
}